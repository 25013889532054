import axios from '@/plugins/http.js';
class CourseReview {
  getAuditList (params) { // 获取文件列表
    return axios.get(`/course/audit-list?${params}`);
  }
  auditCourse (courseId,params) { // 审核
    return axios.post(`/course/${courseId}/audit`,params);
  }
  auditRecord(courseId, params) {
    return axios.get(`/course/${courseId}/audit-log-list?${params}`);
  }
}
export default new CourseReview();

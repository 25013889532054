<template>
  <div>
    <el-form ref="form" :model="formData">
      <el-form-item prop="result" class="position">
        <el-radio-group v-model="formData.result">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="!type || formData.result == 2"
        prop="remark"
        :label="checkLable"
        :rules="{
          required: formData.result == 2,
          message: '请输入驳回理由',
          trigger: 'blur',
        }"
      >
        <el-input
          type="textarea"
          rows="5"
          v-model="formData.remark"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <p v-if="type && formData.result == 1">通过后，资讯将被上架</p>
      <p v-if="type && formData.result == 2">
        驳回后，发布者可修改资讯，再次申请发布
      </p>
      <p v-if="!type && formData.result == 1">通过后，课程将被上架</p>
      <p v-if="!type && formData.result == 2">
        驳回后，发布者可修改课程，再次申请发布
      </p>
      <el-row class="btn_submit">
        <el-button class="btn btn_blue" @click="submitData('form')"
          >提交</el-button
        >
      </el-row>
    </el-form>
  </div>
</template>
<script>
import checkAPI from "@/apis/backStageEndAPI/consultManageAPI";
import API from "@/apis/backStageEndAPI/courseReviewAPI";
export default {
  name: "checkDetail",
  props: ["courseId", "type"],
  data() {
    return {
      checkLable: "审批意见",
      formData: {
        remark: "",
        result: 1,
      },
    };
  },
  mounted() {
    if (this.type) {
      this.checkLable = "驳回理由";
    }
  },
  methods: {
    submitData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type) {
            checkAPI.checkConsult(this.courseId, this.formData).then((res) => {
              this.$emit("changeDialogClose");
            });
          } else {
            API.auditCourse(this.courseId, this.formData).then((res) => {
              this.$emit("changeDialogClose");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    margin-bottom: 16px;
  }
}
p {
  padding: 5px 0;
  color: var(--colorBlue);
}
.btn_submit {
  text-align: center;
}
</style>
